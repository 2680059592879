<template>
  <div class="property-wrapper" v-if="control">
    <ControlBaseProperties
      v-model="control"
      @setExpression="buildExpression($event)"
      :hasExpression="expression != ''"
    />
    <ControlSpecificPanel :control="control">
      <div class="form-group form-group-sm">
        <label for="">{{ $t("titles.form") }}</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="formName"
            ref="formName"
          />
          <div
            class="input-group-addon btn"
            @click.stop.prevent="
              formName = '';
              $refs.formName.focus();
            "
          >
            <i class="fa fa-close"></i>
          </div>
        </div>
      </div>
      <!-- data input does has fixed expression targeting the data id and can not be edited -->
      <div class="form-group form-group-sm">
        <label for="">{{ $t("expression") }}</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            :value="expression"
            disabled
            style="background-color: whitesmoke"
          />
          <div
            class="input-group-addon btn"
            @click.stop.prevent="expression = ''"
          >
            <i class="fa fa-close"></i>
          </div>
        </div>
      </div>
      <label>{{ $t("controls") }} / {{ $tc("button", 2) }}</label>
      <DataValueInputToolbar v-model="inputToolBar" />
      <DataValueCommandStatus :control="control" />
      <TogglePanel :title="$t('control_status')">
        <MultiColorsSelector
          class="multi-color-selector"
          v-model="control.synopticComponent.errorStyle"
          :label="$t('synoptic.error_state')"
        />
        <MultiColorsSelector
          class="multi-color-selector"
          v-model="control.synopticComponent.focusStyle"
          :label="$t('synoptic.focus_state')"
        />
        <MultiColorsSelector
          class="multi-color-selector"
          v-model="control.synopticComponent.pendingStyle"
          :label="$t('synoptic.pending_state')"
        />
      </TogglePanel>
      <TogglePanel :title="$t('synoptic.text_list')" style="margin-top: 10px">
        <DataStateListForm
          v-if="stateImages"
          v-model="dataSource"
          :equipmentData="equipmentData"
        />
      </TogglePanel>
    </ControlSpecificPanel>
  </div>
</template>

<script>
import DataValueCommandStatus from "./data-value-command-status.vue";
import MultiColorsSelector from "@/components/editor/multi-colors-selector";
import DataValueInputToolbar from "@/components/synoptic/property-editor/controls/data-value-input-toolbar.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import StateControl from "@/components/synoptic/property-editor/controls/state-control.vue";
export default {
  name: "DataValueSelect",
  extends: StateControl,
  components: {
    DataValueCommandStatus,
    MultiColorsSelector,
    DataValueInputToolbar,
    TogglePanel
  },
  data() {
    return {
      enabled: {},
      dataSourceType: "local",
      dataSourceId: "",
      stateImages: [],
      lastData: null,
      controlStatus: false
    };
  },
  computed: {
    inputToolBar: {
      set(value) {
        let widget = this?.control?.synopticComponent || {};
        this.$set(widget, "restoreButton", value.restoreButton);
        this.$set(widget, "saveButton", value.saveButton);
        this.$set(widget, "toolbarVisibility", value.visibility);
      },
      get() {
        let widget = this?.control?.synopticComponent || {};
        return {
          restoreButton: widget?.restoreButton || false,
          saveButton: widget?.saveButton || false,
          visibility:
            widget?.toolbarVisibility !== undefined
              ? widget.toolbarVisibility
              : widget?.restoreButton || widget?.saveButton // never set before
              ? "always"
              : "never"
        };
      }
    },
    expression: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "expression", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.expression || "";
      }
    },
    formName: {
      set(value) {
        if (this?.control?.synopticComponent) {
          this.$set(this.control.synopticComponent, "formName", value);
        }
      },
      get() {
        return this?.control?.synopticComponent?.formName ?? "";
      }
    }
  },
  methods: {
    buildExpression(source) {
      this.expression = source
        .replace(/\$\('[\d]+/, "$('.")
        .replace(/\?\.name/, ".id");
      this.$utils.notifyUser(this, this.$t("titles.expression_created"));
    }
  }
};
</script>

<style scoped>
.state-list {
  max-height: 35dvh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 60px;
}

.state-list::-webkit-scrollbar {
  width: 8px;
}

.state-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.state-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.state-item {
  /* margin-top: 1rem; */
  margin: 5px 0;
}

.state-item.open {
  /* margin-top: 1rem; */
  padding: 0 0 20px 0;
  margin-bottom: 15px;
}

.state-item:not(:last-of-type) {
  /* padding-bottom: 5px; */
  border-bottom: 1px solid #dcdcdc;
}

.form-group-min {
  margin-bottom: 0px;
}

.form-group-no-margin {
  margin-top: -14px;
  text-align: left;
}
.form-group-no-margin > select {
  padding: 0 6px;
}
.fa-btn:hover {
  cursor: pointer;
}
.fa-btn-disabled {
  color: gray;
}
.fa-btn-disabled:hover {
  cursor: not-allowed;
}
.multi-color-selector {
  margin-bottom: 0.5em;
}
.clicable:hover {
  background-color: transparent;
}
</style>
